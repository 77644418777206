<template>
	<Layout
		v-if="is_view"
		:Axios="Axios"
		:Notify="Notify"
		:filter="filter"
		:date="date"
		:rules="rules"
		:Base64="Base64"
		:metaInfo="metaInfo"
		:TOKEN="TOKEN"
		:member_info="member_info"
		:seller_info="seller_info"
		:mall_info="mall_info"
		:codes="codes"
		:pdt_search="pdt_search"
	/>
</template>

<script>
	
	import { Axios } from '@/resources/axios/axios'
	import { Notify } from '@/components/AlertMsg'
	import { filter } from '@/resources/filter/filter'
	import { date } from '@/resources/date/date'
	import { rules } from '@/resources/rules/rules'
	import { metaInfo } from '@/resources/config/metainfo'

	import { Base64 } from 'js-base64'

	import Layout from '@/view/Layout/Layout.vue'
	import {codes} from "@/resources/config/codes";

export default {

	name: 'App'
	,metaInfo: metaInfo
	,components: { Layout }
	,data: () => ({
		Axios: Axios
		,Notify: Notify
		,date: date
		,filter: filter
		,rules: rules
		,Base64: Base64
		,metaInfo: metaInfo
		,TOKEN: ''
		,codes: codes
		,member_info: {

		}
		,seller_info: {

		}
		, pdt_search: {

		}
		,mall_info: {

		}
		, is_code: false
	})
	, computed: {
		is_view: function(){
			let t = false

			if(this.mall_info.seller_id && this.is_code){
				t = true
			}
			return t
		}
	}
	,methods: {
		isAuth: async function(){
			console.log('isAuth start !!')
			let TOKEN = sessionStorage.getItem(process.env.VUE_APP_NAME + 'T')
			let skip = false
			let except = []
			let path = document.location.href
			except.forEach(function (val) {
				if (path.toLowerCase().indexOf(val) > -1) {
					skip = true
					return false
				}
			})
			if(skip){
				console.log('isAuth skip ! do next !!')
			}else {
				if (!TOKEN || TOKEN === 'false') {
					console.log('not auth ! to login !!')
					await this.getBaseInfo()
					await this.getBaseCode()
				}else{
					await this.getBaseInfo()
					await this.getBaseCode()
				}
			}

			console.log('isAuth finished !!')
		}
		,toLogin: function(){
			//this.$router.push({ name: 'Login' })
			document.location.href = '/Auth/Login'
		}
		,getBaseInfo: async function(){
			try{
				const result = await this.Axios({
					method: 'get'
					,url: 'member/getBaseInfo'
					,data: {
					}
				})

				if(result.success){
					this.member_info = result.data.member_info
					if(this.member_info){
						this.member_info = JSON.parse(Base64.decode(this.member_info))
					}
					this.seller_info = result.data.seller_info
					if(this.seller_info){
						this.seller_info = JSON.parse(Base64.decode(this.seller_info))
					}
					this.mall_info = result.data.mall_info

					if(this.mall_info){
						this.mall_info = JSON.parse(Base64.decode(this.mall_info))
					}

					this.pdt_search = result.data.pdt_search

					if(this.pdt_search){
						this.pdt_search = JSON.parse(Base64.decode(this.pdt_search))
					}
				}else{
					throw result.message
				}
			}catch (e) {
				console.log(e)
				this.$bus.$emit('notify', { type: 'error', message: e})
				sessionStorage.removeItem(process.env.VUE_APP_NAME + 'T')
				sessionStorage.removeItem(process.env.VUE_APP_NAME + 'T2')
				// await this.$router.push({ name: 'Login'})
			}
		}
		,getBaseCode: async function(){
			try{
				const result = await this.Axios({
					method: 'get'
					,url: 'management/getCodeList'
					,data: {
					}
				})

				if(result.success){
					this.setCode(result.data.result)
					this.is_code = true
				}else{
					throw result.message
				}
			}catch (e) {
				console.log(e)
				this.toLogin()
			}
		}
		,setCode: function(code_list){
			let list = {}
			code_list.forEach(function(code){
				let main = list[code.main_code]
				if(!main){
					code.items = []
					list[code.main_code] = code
				}
				list[code.main_code].items.push(code)
			})

			Object.assign(list, this.codes)
			this.$set(this, 'codes', list)
		}

		, logout: function(){
			sessionStorage.clear()
			localStorage.removeItem('login_info')
			localStorage.removeItem(process.env.VUE_APP_NAME + 'A')
			setTimeout( () => {
				this.$storage.push({ name: 'Index', type: 'href'})
			}, 500)
		}
	}
	,created() {

		this.$bus.$on('logout', () => {
			this.logout()
		})

		this.isAuth()
	}
};
</script>
