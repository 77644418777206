<template>
	<div
		class="side"
	>
		<div
			class="bg-side"
			@click="toggleSide"
		></div>

		<div
			class="side-container flex-column"
		>
			<div class="justify-space-between prl-10">
				<div
					class="logo-position text-center flex-1"
				>
					<template
						v-if="$production"
					>
						<img
							v-if="seller_info.shop_logo"
							:src="seller_info.shop_logo"
							class="side-logo"
						/>
						<div
							v-else-if="seller_info.shop_name"
							class="pa-20 size-px-16 font-weight-bold  under-line-82"
						>{{ seller_info.shop_name }}</div>
						<img
							v-else
							:src="$shop_logo" style="width: 40%"
						/>
					</template>
					<template
						v-else
					>
						<img
							v-if="seller_info.shop_logo"
							:src="seller_info.shop_logo"
							class="side-logo"
						/>
						<div
							v-else-if="seller_info.shop_name"
							class="pa-20 size-px-16 font-weight-bold  under-line-82"
						>{{ seller_info.shop_name }}</div>
						<div
							v-else
							class="color-333 size-px-16 font-weight-bold under-line"
						>{{ $production_title }}</div>
					</template>
				</div>
				<v-icon
					@click="toggleSide"
					class=""
				>mdi mdi-close</v-icon>
			</div>

			<div
				v-if="is_member"
				class="flex-row justify-space-between under-line pa-10 bg-base color-white"
			>
				<span class="color-white size-px-14 font-weight-700">
					{{ member_info.member_name }} 님
				</span>

				<button
					class="size-px-10 box prl-10 bg-gray color-eee ml-10 line-height-160"
					@click="logout"
				>로그아웃</button>
			</div>
			<div
				v-else
				class="flex-row justify-space-between under-line pa-10 bg-base color-white"
			>
				<button
					class="size-px-10 box prl-10 bg-gray color-eee ml-10 line-height-160"
					@click="toLogin"
				>로그인</button>
			</div>



			<ul
				class="side-content overflow-y-auto"
			>
				<template
					v-if="items_banner_category.length > 0"
				>
					<template
						v-for="item in items_banner_category"
					>
					<li
						:key="item.category_code"
						class="li-side-content justify-space-between"
						:class="'li-side-depth-' + (Number(item.depth -1))"

						@click="toItem(item.banner_link)"
					>
						<span class="font-weight-400">{{ item.category_name }}</span>
						<v-icon
						>mdi mdi-chevron-right</v-icon>
					</li>
					</template>
				</template>
				<li
					v-if="is_member"
					class="li-side-content justify-space-between"
					@click="toMyPage"
				>
					<span class="font-weight-400">마이 페이지</span>
					<v-icon
					>mdi mdi-chevron-right</v-icon>
				</li>
			</ul>

			<div
				v-if="true"
				class="mt-auto"
			>
				<div class="pa-10">
					<dl>
						<dt>상호명:</dt> <dd>{{ mall_info.shop_name }}</dd> <br/>
						<dt>대표자:</dt> <dd>{{ mall_info.shop_ceo }}</dd> <br/>
						<dt>주소:</dt> <dd>{{ mall_info.shop_address }} {{ mall_info.shop_address_detail }}</dd><br/>
						<dt>사업자 번호:</dt> <dd>{{ mall_info.shop_number1 }}</dd><br/>
						<dt>통신판매번호:</dt> <dd>{{ mall_info.shop_number2 }}</dd><br/>
						<dt>고객센터:</dt> <dd>{{ mall_info.shop_tell }}</dd><br/>
						<dt>이메일:</dt> <dd>{{ mall_info.shop_email }}</dd>
					</dl>
				</div>
			</div>
		</div>
	</div>
</template>

<script>

	export default{
		name: 'Side'
		,props: ['Axios', 'member_info', 'category', 'seller_info', 'is_member', 'items_banner_category', 'mall_info']
		,data: function(){
			return {
				items: this.category
			}
		}
		,methods: {
			toItem: function(category){
				this.toggleSide()

				this.$storage.setQuery({ pdt_category: category})
				this.$storage.push({ name: 'ProductList', params: { category: category} })
			}
			,toggleSide: function(){
				this.$emit('toggleSide')
			}
			,logout: function(){
				let auto_login = localStorage.getItem(process.env.VUE_APP_NAME + 'A')

				if(auto_login) {

					if(confirm('로그아웃시 자동로그인도 해제됩니다. 로그아웃 하시겠습니까?')) {

						localStorage.removeItem(process.env.VUE_APP_NAME + 'A')
						localStorage.removeItem(process.env.VUE_APP_NAME + 'N')
						localStorage.removeItem(process.env.VUE_APP_NAME + 'P')

						this.toggleSide()
						sessionStorage.clear()
						document.location.href = '/Auth/Login'
					}

				}else{

					if(confirm('로그아웃 하시겠습니까?')){

						this.toggleSide()
						sessionStorage.clear()
						document.location.href = '/Auth/Login'
					}
				}
			}
			,toMyPage: function(){
				this.toggleSide()
				this.$storage.push({ name: 'MyPage'})
			}
			, toLogin: function(){
				this.toggleSide()
				this.$storage.push({ name: 'Login'})
			}
		}
		,created: function(){
		}
	}
</script>

<style>

	.side {
		width: 100%; height: 100%;
		position: fixed; left: 0; top: 0; z-index: 98;
	}
	.bg-side {
		width: 100%; height: 100%;
		position: fixed; left: 0; top: 0; z-index: 98;
		background-color: gray;
		opacity: 0.5;
	}
	.side-container{
		width: 80%; height: 100%;

		position: relative;  z-index: 99;
		background-color: white;
		overflow-y: auto;
	}

	.side-container {

	}

	.li-side-content {
		padding: 13px 23px;
		border-bottom: 1px solid #ddd;
	}
	.li-side-depth-1 {
		text-indent: 0px;
	}
	.li-side-depth-2 {
		text-indent: 10px;
	}
	.li-side-depth-3 {
		text-indent: 20px;
	}
	.li-side-depth-4 {
		text-indent: 30px;
	}

	dt, dd { display: inline-block}

	.side-logo {
		max-width: 140px;
	}
</style>